import axios from "axios";
import { useState, useEffect } from "react";
import Nav from "./Nav";
import { useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { link } from "./url";

const Bill = () => {
  const [value, setValue] = useState([]);
  const [id, setid] = useState("");
  const nav = useNavigate();
  var MyDate = new Date();
  var MyDateString =
    MyDate.getFullYear() +
    "-" +
    ("0" + (MyDate.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + MyDate.getDate()).slice(-2);
  const [data, setData] = useState([]);
  const [date, setDate] = useState(MyDateString);
  const [date1, setDate1] = useState(MyDateString);
  const [v, setV] = useState([]);
  const [bal, setBal] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [modalShiftData, setModalShiftData] = useState("");
  const [modalAmountShow, setModalAmountShow] = useState(false);
  const [modalAmount, setModalAmount] = useState("");

  useEffect(() => {
    axios
      .post(link + "salman/bill", {
        curdate: date,
        curdate1: date1,
      })
      .then((response) => {
        setData(response.data["out"]);
        setValue(response.data["out"]);
        setBal(response.data["sum"]);
      });
  }, [v]);

  const submitDate = () => {
    axios
      .post(link + "salman/billful", {
        curdate: date,
        curdate1: date1,
      })
      .then((response) => {
        setData(response.data["out"]);
        setValue(response.data["out"]);
        setBal(response.data["sum"]);
      });
  };

  const print = () => {
    nav("/printdata", { state: data });
  };

  const searchData = (e) => {
    const r = [];
    for (var k of value) {
      if (k[3] === null) {
        k[3] = "";
      }
      if (k[2] === null) {
        k[2] = "";
      }
      if (
        k[0] === e ||
        k[1] === e ||
        k[2].toLowerCase().indexOf(e.toLowerCase()) !== -1 ||
        k[3].toLowerCase().indexOf(e.toLowerCase()) !== -1 ||
        k[3].toLowerCase().indexOf(e.toLowerCase()) !== -1 ||
        (k[4] + "").toLowerCase().indexOf(e.toLowerCase()) !== -1
      ) {
        r.push(k);
      }
    }
    setData(r);
  };

  const setAmount = (e, r) => {
    var va = [];
    for (var k of data) {
      if (k[0] === r) {
        k[15] = e;
      }
      va.push(k);
    }
    setData(va);
  };

  const setDateField = (e, r) => {
    var va = [];
    for (var k of data) {
      if (k[0] === r) {
        k[16] = e;
      }
      va.push(k);
    }
    setData(va);
  };

  const submitData = (e) => {
    e.push(MyDateString);

    if (e[15] !== "") {
      axios
        .post(link + "salman/addbill", {
          v: e,
        })
        .then(() => {
          setV([...v, e[0]]);
        });
    } else {
      alert("kindly add data");
    }
  };

  const handleShiftClick = (shiftData, id) => {
    setModalShiftData(shiftData);
    setid(id);
    setModalShow(true);
  };

  const [mobile, setmobile] = useState("");
  const [m, setm] = useState("");
  const handleAddAmountClick = (id, me, mes) => {
    setid(id);
    setmobile(me);
    setm(mes);
    setModalAmountShow(true);
  };

  const submitAmount = () => {
    axios
      .post(link + "salman/addamount", {
        id: id,
        amount: modalAmount,
        mobile: mobile,
        message: m,
      })
      .then(() => {
        alert("s");
        axios
          .post(link + "salman/bill", {
            curdate: date,
            curdate1: date1,
          })
          .then((response) => {
            setData(response.data["out"]);
            setValue(response.data["out"]);
            setBal(response.data["sum"]);
            setModalAmountShow(false);
            setid("");
            setmobile("");
            setm("");
          });
      });
  };

  return (
    <>
      <Nav />
      <div style={{ marginTop: "15%" }}>
        <h1>Bill - {data.length}</h1>
        {window.localStorage.getItem("pro") === "salman" ? (
          <h1>Total Pending- {bal}</h1>
        ) : (
          <></>
        )}

        <div className="row">
          <div className="col-6">
            <input
              type="text"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              className="form-select"
              style={{ width: "100%" }}
            />
          </div>
          <div className="col-6">
            <input
              type="text"
              value={date1}
              onChange={(e) => setDate1(e.target.value)}
              className="form-select"
              style={{ width: "100%" }}
            />
          </div>
          <div className="col-6">
            <input
              type="submit"
              onClick={submitDate}
              className="btn btn-danger"
              style={{ width: "100%" }}
            />
          </div>
          <div className="col-6">
            <button
              className="btn btn-primary"
              onClick={print}
              style={{ width: "100%" }}
            >
              Print
            </button>
          </div>
        </div>

        <input
          type="search"
          onChange={(e) => searchData(e.target.value)}
          style={{ marginTop: "0%" }}
          className="form-select"
          placeholder="Search"
        />
        <div className="table-responsive">
          <table
            className="table table-bordered"
            id="table_id"
            style={{ marginTop: "0%" }}
          >
            <thead>
              <tr>
                <th>Billid</th>
                <th>Shift</th>
                <th>Name</th>
                <th>Balance</th>
                <th>PAmount</th>
                <th>Cdate</th>
                <th>Sms</th>
                <th>Submit</th>
                <th>Area</th>
                <th>Mobile</th>
                <th>Product</th>
                <th>Price</th>
                <th>Mode</th>
                <th>Day</th>
                <th>Paymentdate</th>
                <th>Add Amount</th> {/* New Column */}
              </tr>
            </thead>
            <tbody>
              {data.map((d) => {
                return (
                  <tr key={d[0]}>
                    <td>{d[0]}</td>
                    <td
                      onClick={() => handleShiftClick(d[13], d[0])}
                      style={{
                        cursor: "pointer",
                        color: "blue",
                        textDecoration: "underline",
                      }}
                    >
                      {d[13]}
                    </td>
                    <td>{d[2]}</td>
                    <td>{d[9]}</td>
                    <td>
                      <input
                        type="text"
                        style={{ width: "100%" }}
                        value={d[15]}
                        onChange={(e) => setAmount(e.target.value, d[0])}
                        placeholder="Amount"
                      />
                    </td>
                    <td>
                      <input
                        type="date"
                        value={d[16]}
                        onChange={(e) => setDateField(e.target.value, d[0])}
                      />
                    </td>
                    <td>
                      {d[14] === 1 ? (
                        <input
                          type="checkbox"
                          checked
                          onChange={() => {
                            var value = {
                              i: d[0],
                              message: 0,
                            };
                            console.log(value);
                            axios
                              .post(link + "salman/updatemessage", value)
                              .then(() => {
                                window.location.reload();
                              });
                          }}
                        />
                      ) : (
                        <input
                          type="checkbox"
                          onChange={() => {
                            var value = {
                              i: d[0],
                              message: 1,
                            };
                            console.log(value);
                            axios
                              .post(link + "salman/updatemessage", value)
                              .then(() => {
                                window.location.reload();
                              });
                          }}
                        />
                      )}
                    </td>
                    <td>
                      <input
                        type="button"
                        value="add"
                        onClick={() => submitData(d)}
                      />
                    </td>
                    <td>{d[3]}</td>
                    <td>{d[4]}</td>
                    <td>{d[5]}</td>
                    <td>{d[6]}</td>
                    <td>{d[7]}</td>
                    <td>{d[8]}</td>
                    <td>{d[10]}</td>
                    <td>
                      <Button
                        variant="success"
                        onClick={() => handleAddAmountClick(d[0], d[4], d[14])}
                      >
                        Add Amount
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      {/* Shift Modal */}
      <Modal show={modalShow} onHide={() => setModalShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Shift Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <select
            onChange={(e) => {
              setModalShiftData(e.target.value);
            }}
            value={modalShiftData}
            className="form-select"
          >
            <option>{modalShiftData}</option>
            <option>G</option>
            <option>M</option>
            <option>E</option>
          </select>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              var value = {
                value: modalShiftData,
                id: id,
              };
              console.log(value);
              axios.post(link + "salman/updateshift", value).then((res) => {
                axios
                  .post(link + "salman/bill", {
                    curdate: date,
                    curdate1: date1,
                  })
                  .then((response) => {
                    console.log(response.data);
                    setData(response.data["out"]);
                    setValue(response.data["out"]);
                    setBal(response.data["sum"]);
                    setModalShow(false);
                  });
              });
            }}
          >
            Submit
          </Button>
          <Button variant="secondary" onClick={() => setModalShow(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Add Amount Modal */}
      <Modal show={modalAmountShow} onHide={() => setModalAmountShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Amount for {id}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="number"
            className="form-control"
            value={modalAmount}
            onChange={(e) => setModalAmount(e.target.value)}
            placeholder="Enter Amount"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={submitAmount}>
            Submit
          </Button>
          <Button variant="secondary" onClick={() => setModalAmountShow(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Bill;
