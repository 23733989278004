import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import Nav from "./Nav";
import { link } from "./url";
const Sales = () => {
  const nav = useNavigate();
  const [data, setData] = useState([]);
  const [value, setvalue] = useState([]);
  const [dx, setDx] = useState(0);
  useEffect(() => {
    axios.get(link + "salman/sales").then((response) => {
      setData(response.data);
      setvalue(response.data);
    });
  }, []);
  const check = (e) => {
    var val = data;
    for (var va of val) {
      if (va[0] === e) {
        if (va[14] === "s") {
          va[14] = "no";
        } else if ((va[14] = "no")) {
          va[14] = "s";

          axios
            .post(link + "salman/salesupdate", { va: va })
            .then((response) => {
              alert("success");
            });
        }

        break;
      }
    }

    if (dx === 0) {
      setDx(1);
    } else {
      setDx(0);
    }
  };
  const searchdata = (e) => {
    const r = [];
    for (var k of value) {
      if (k[3] === null) {
        k[3] = "";
      }
      if (k[2] === null) {
        k[2] = "";
      }
      if (
        k[1] == e ||
        k[0] == e ||
        k[2].toLowerCase().indexOf(e.toLowerCase()) !== -1 ||
        k[3].toLowerCase().indexOf(e.toLowerCase()) !== -1 ||
        k[3].toLowerCase().indexOf(e.toLowerCase()) !== -1 ||
        (k[4] + "").toLowerCase().indexOf(e.toLowerCase()) !== -1
      ) {
        r.push(k);
      }
    }
    setData(r);
  };
  const viewcustomer = (e) => {
    nav("/viewcustomer", { state: e });
  };

  const viewsales = (e) => {
    var k = [];
    k.push(e[0]);
    k.push(e[1]);
    k.push(e[5]);
    k.push(e[9]);
    k.push(e[6]);
    k.push(e[7]);
    k.push(e[8]);
    k.push(e[10]);
    k.push(e[11]);
    k.push(e[8]);
    k.push(e[12]);
    nav("/viewsales", { state: k });
  };
  const setCname = (e, de) => {
    var val = [];
    for (var va of data) {
      if (va[0] === de) {
        va[2] = e;
      }
      val.push(va);
    }

    setData(val);
  };
  const setArea = (e, de) => {
    var val = [];
    for (var va of data) {
      if (va[0] === de) {
        va[3] = e;
      }
      val.push(va);
    }

    setData(val);
  };
  const setMobile = (e, de) => {
    var val = [];
    for (var va of data) {
      if (va[0] === de) {
        va[4] = e;
      }
      val.push(va);
    }

    setData(val);
  };
  const setPname = (e, de) => {
    var val = [];
    for (var va of data) {
      if (va[0] === de) {
        va[5] = e;
      }
      val.push(va);
    }

    setData(val);
  };
  const setSalesdate = (e, de) => {
    var val = [];
    for (var va of data) {
      if (va[0] === de) {
        va[6] = e;
      }
      val.push(va);
    }

    setData(val);
  };
  const setPrice = (e, de) => {
    var val = [];
    for (var va of data) {
      if (va[0] === de) {
        console.log(va[9]);
        va[9] = e;
      }
      val.push(va);
    }

    setData(val);
  };
  const setBal = (e, de) => {
    var val = [];
    for (var va of data) {
      if (va[0] === de) {
        console.log(va[10]);
        va[10] = e;
      }
      val.push(va);
    }

    setData(val);
  };
  const setPaydate = (e, de) => {
    var val = [];
    for (var va of data) {
      if (va[0] === de) {
        va[8] = e;
      }
      val.push(va);
    }

    setData(val);
  };
  const setDay = (e, de) => {
    var val = [];
    for (var va of data) {
      if (va[0] === de) {
        va[7] = e;
      }
      val.push(va);
    }

    setData(val);
  };
  const setPdate = (e, de) => {
    var val = [];
    for (var va of data) {
      if (va[0] === de) {
        va[11] = e;
      }
      val.push(va);
    }

    setData(val);
  };

  const setshift = (e, de) => {
    var val = [];
    for (var va of data) {
      if (va[0] === de) {
        va[15] = e;
      }
      val.push(va);
    }

    setData(val);
  };
  const deletec = (e) => {
    axios.post(link + "salman/deletesales", { id: e }).then((response) => {
      window.location.reload();
    });
  };
  return (
    <>
      <Nav />
      <div style={{ marginTop: "15%" }}>
        <NavLink className="nav-link" to="/allsales">
          <button className="btn btn-primary">Completed Customer</button>
        </NavLink>
        <h3>Current Customer</h3>
        <input
          type="search"
          onChange={(e) => searchdata(e.target.value)}
          className="form-select"
          placeholder="Search"
        />
        <div className="table-responsive">
          <table className="table table-bordered" id="table_id">
            <thead>
              <tr>
                <th>Edit</th>
                <th>Cid</th>
                <th>Salesid</th>

                <th>Cname</th>
                <th>Area</th>
                <th>Mobile No</th>
                <th>Product Name</th>
                <th>Sales Date</th>
                <th>Day</th>
                <th>Pay Date</th>
                <th>Price</th>
                <th>Balance</th>
                <th>Payment date</th>
                <th>Shift</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {data.map((d) => {
                if (d[14] === "s") {
                  return (
                    <tr key={d[0]}>
                      <td>
                        {window.localStorage.getItem("pro") === "salman" ? (
                          <input
                            type="checkbox"
                            onChange={() => check(d[0])}
                            value={dx}
                          />
                        ) : (
                          <></>
                        )}
                      </td>
                      <td>
                        <button
                          className="btn btn-primary"
                          onClick={() => viewsales(d)}
                        >
                          {d[0]}
                        </button>
                      </td>
                      <td>
                        <button
                          className="btn btn-danger"
                          onClick={() => viewcustomer(d[1])}
                        >
                          {d[1]}
                        </button>
                      </td>

                      <td>{d[2]}</td>
                      <td>{d[3]}</td>
                      <td>{d[4]}</td>
                      <td>{d[5]}</td>
                      <td>{d[6]}</td>
                      <td>{d[7]}</td>
                      <td>{d[8]}</td>
                      <td>{d[9]}</td>
                      <td>{d[10]}</td>
                      <td>{d[11]}</td>
                      <td>{d[15]}</td>
                      <td>
                        {window.localStorage.getItem("pro") === "salman" ? (
                          <button
                            className="btn btn-primary"
                            onClick={() => deletec(d[0])}
                          >
                            Delete
                          </button>
                        ) : (
                          <></>
                        )}
                      </td>
                    </tr>
                  );
                } else {
                  return (
                    <tr key={d[0]}>
                      <td>
                        <input
                          type="checkbox"
                          onChange={() => check(d[0])}
                          value={dx}
                          checked
                        />
                      </td>
                      <td>
                        <button
                          className="btn btn-primary"
                          onClick={() => viewsales(d)}
                        >
                          {d[0]}
                        </button>
                      </td>
                      <td>
                        <button
                          className="btn btn-danger"
                          onClick={() => viewcustomer(d[1])}
                        >
                          {d[1]}
                        </button>
                      </td>
                      <td>
                        <input
                          type="text"
                          value={d[2]}
                          onChange={(e) => setCname(e.target.value, d[0])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={d[3]}
                          onChange={(e) => setArea(e.target.value, d[0])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={d[4]}
                          onChange={(e) => setMobile(e.target.value, d[0])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={d[5]}
                          onChange={(e) => setPname(e.target.value, d[0])}
                        />
                      </td>
                      <td>
                        <input
                          type="date"
                          value={d[6]}
                          onChange={(e) => setSalesdate(e.target.value, d[0])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={d[7]}
                          onChange={(e) => setDay(e.target.value, d[0])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={d[8]}
                          onChange={(e) => setPaydate(e.target.value, d[0])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={d[9]}
                          onChange={(e) => setPrice(e.target.value, d[0])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={d[10]}
                          onChange={(e) => setBal(e.target.value, d[0])}
                        />
                      </td>
                      <td>
                        <input
                          type="date"
                          value={d[11]}
                          onChange={(e) => setPdate(e.target.value, d[0])}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={d[15]}
                          onChange={(e) => setshift(e.target.value, d[0])}
                        />
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Sales;
